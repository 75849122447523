// 诚信条款管理
import  request from "../utils/request";

// 获取诚信条款
export const getGoodfaithclauseList = (params) => {
    return request({
        method: 'get',
        url: `/goodfaithclause/queryselect`,
        params
    })
}

// 查询我的诚信分数
export const getMyGoodFaithBehavior = (params) => {
    return request({
        method: 'get',
        url: `/MyGoodFaithBehavior/getmygoodfaithbehavior`,
        params
    })
}

// 申诉
export const appealMyGoodFaithBehavior = (data) => {
    return request({
        method: 'post',
        url: `/MyGoodFaithBehavior/Appeal`,
        data,
        timeout: 36000
    })
}

// 获取诚信申请流程
export const getGoodFaithAppealDetail = (params) => {
    return request({
        method: 'get',
        url: `/MyGoodFaithBehavior/GetGoodFaithClauseAppeal`,
        params
    })
}

// 获取诚信条款详情
export const getGoodFaithDetail = (params) => {
    return request({
        method: 'get',
        url: `/MyGoodFaithBehavior/getGoodFaithClauseContext`,
        params
    })
}