<template>
    <a-modal :width="600" :visible.sync="visible" @cancel="handleCancel" title="项目详情">
    <a-spin :spinning="loading" tip="">
        <a-form-model ref="formRef" :model="formData" style="width: 95%;margin: 0 auto;" :colon="true" :footer="hasEdit" :labelCol="{
            xs: { span: 24 },
            sm: { span: 6 },
        }" :wrapper-col="{ span: 18 }" v-if="hasEdit && !onlyPreview" :rules="rules">
             <a-form-model-item label="课题组" prop="researchGroupName">
                <span> {{  initForm.researchGroupName  }} </span>
             </a-form-model-item>
            <a-form-model-item label="项目名称" prop="name">
                <a-input v-model="formData.name" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="（拟/已）投稿期刊" prop="magazine">
                <a-input v-model="formData.magazine" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="项目编号">
                {{ formData.code }}
            </a-form-model-item>
            <a-form-model-item label="项目负责人">
                {{ formData.headName }}
            </a-form-model-item>
            <a-form-model-item label="创建时间">
                {{ formData.createTime }}
            </a-form-model-item>
            <a-form-model-item label="研究目的" prop="objective">
                <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.objective" :maxLength="200" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="预期结果" prop="result">
                <a-textarea :autoSize="{ minRows: 4 }" v-model="formData.result" :maxLength="200" placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item label="预计起止时间" prop="dateRange">
                <a-range-picker valueFormat="YYYY-MM-DD" v-model="formData.dateRange" style="width: 100%;" />
            </a-form-model-item>
        </a-form-model>
        <div v-else>
            <a-form-model ref="formRef" :model="formData" style="width: 95%;margin: 0 auto;" :colon="true" :labelCol="{
            xs: { span: 24 },
            sm: { span: 6 },
        }" :wrapper-col="{ span: 18 }">
         <a-form-model-item label="课题组" prop="researchGroupName">
                <span> {{ originData.researchGroupName  }} </span>
             </a-form-model-item>
                <a-form-model-item label="项目名称" prop="name">
                    {{ originData.name }}
                </a-form-model-item>
                <a-form-model-item label="（拟/已）投稿期刊" prop="magazine">
                    {{ originData.magazine }}
                </a-form-model-item>
                 <a-form-model-item label="项目编号">
                    {{ originData.code }}
                </a-form-model-item>
                <a-form-model-item label="项目负责人">
                    {{ originData.headName }}
                </a-form-model-item>
                <a-form-model-item label="创建时间">
                    {{ originData.createTime }}
                </a-form-model-item>
                <a-form-model-item label="研究目的">
                    {{ originData.objective }}
                </a-form-model-item>
                <a-form-model-item label="预期结果">
                    {{ originData.result }}
                </a-form-model-item>
                <a-form-model-item label="预计起止时间">
                    {{ originData.startTime }} - {{ originData.endTime }}
                </a-form-model-item>
            </a-form-model>
        </div>
    </a-spin>
        <template slot="footer" v-if="hasEdit && !onlyPreview">
             <div class="btns-ctn">
                <a-button class="concel-btn" :loading="isSubmitting" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :loading="isSubmitting" :disabled="isSame" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
        <template slot="footer" v-else-if="showJump">
            <div class="btns-ctn">
                <a-button type="primary" @click="handleJump">
                        前往项目主页
                </a-button>
            </div>
        </template>
        <template slot="footer" v-else>
            <div></div>
        </template>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { getProjectDetail, updateProjectInfo } from '../../../api/projectService';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        id: {
            type: String | Number | null,
            required: true
        },
        // 是否是只预览
        onlyPreview: {
            type: Boolean,
            default: false
        },
        // 是否显示跳转项目页脚
        showJump: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 是否有权修改
        const hasEdit = ref(false);
        // 表单手机
        const formData = ref({
            name: null,
            magazine: '',   // 期刊
            objective: null,
            result: null,
            createTime: null,
            headName: null,
            dateRange: []
        })
        // 初始表单
        const initForm = ref({

        })

        // 原始数据
        const originData = ref({

        })
        // 是否一样
        const isSame = computed(() => {
            return JSON.stringify(initForm.value) === JSON.stringify(formData.value);
        })
        // 表单实例
        const formRef = ref(null);

        const loading = ref(false);
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                loading.value = true;
                originData.value = {
                    
                }
                formRef.value && formRef.value.resetFields();
                initForm.value = {
                    name: null,
                    magazine: '',   // 期刊
                    objective: null,
                    result: null,
                    createTime: null,
                    headName: null,
                    dateRange: [],
                }
                const res = await getProjectDetail({
                    id: props.id
                })
                if(res.code === 200) {
                    hasEdit.value = res.data[0].hasRole;
                    originData.value = res.data[0];
                    if(hasEdit.value) {
                        formData.value.researchGroupName = res.data[0].researchGroupName;
                        formData.value.name = res.data[0].name;
                        formData.value.code =  res.data[0].code;
                        formData.value.magazine = res.data[0].magazine;
                        formData.value.objective = res.data[0].objective;
                        formData.value.result = res.data[0].result;
                        formData.value.createTime = res.data[0].createTime;
                        formData.value.headName = res.data[0].headName;
                        formData.value.dateRange = [res.data[0].startTime.split(' ')[0], res.data[0].endTime.split(' ')[0]];
                        initForm.value = JSON.parse(JSON.stringify(formData.value));
                    }
                    else {
                        formData.value = res.data[0];
                    }
                }
                loading.value = false;
            }
        })
        // 是否正在提交
        const isSubmitting = ref(false);

        // 确认修改信息
        const handleConfirm = () => {
            formRef.value && formRef.value.validate(async(valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await updateProjectInfo({
                        id: props.id,
                        name: formData.value.name,
                        objective: formData.value.objective,
                        result: formData.value.result,
                        startTime: formData.value.dateRange[0],
                        endTime: formData.value.dateRange[1],
                        magazine: formData.value.magazine
                    });
                    if(res.code === 200 || res.code === 204) {
                        context.emit('update:visible',false);
                        context.emit('onUpdateProjectInfo', {
                            projectName: formData.value.name
                        });
                    }
                    isSubmitting.value = false;
                }
            })
        }
        const rules = ref({
            name: [{
                required: true,
                message: '请输入',
            },{
                max: 50,
                message: '不能超过50个字符'
            }],
            magazine: [{
                max: 200,
                message: '不能超过200个字符'
            }],
            objective: [{
                required: true,
                message: `请输入`,
                whitespace: true,
            }, {
                max: 200,
                message: '不能超过200个字符'
            }],
            result: [{
                required: true,
                message: `请输入`,
                whitespace: true,
                //max: 200
            }, {
                max: 200,
                message: '不能超过200个字符'
            }],
            dateRange: [{
                required: true,
                message: '请选择'
            }]
        })

        // 跳转至项目主页
        const handleJump = () => {
            const { $router } = context.root;
            const href = $router.resolve({
                name: 'itemCenter',
                query: {
                    projectId: props.id
                }
            });
            window.open(href.href, '_blank')
        }
        return {
            handleCancel,
            hasEdit,
            formData,
            formRef,
            handleConfirm,
            rules,
            isSame,
            loading,
            handleJump,
            isSubmitting,
            initForm,
            originData
        }
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>