<template>
    <a-modal :width="600" title="实验授权" :visible.sync="visible" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在加载中...">
            <div class="auth-ctn">
                <div class="view-only">
                    <div class="view-only-title">
                        仅查看
                    </div>
                    <a-select mode="multiple" v-model="readUsers" style="width: 100%" placeholder="请选择" @change="handleReadChange">
                        <a-select-option v-for="(item, index) in projectUsers" :key="item.userId" :value="item.userId">
                            {{ item.userName }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="editable">
                    <div class="editable-title">
                        可编辑
                    </div>
                    <a-select mode="multiple" v-model="readAndWriters" style="width: 100%" placeholder="请选择" @change="handelWriteChange">
                        <a-select-option v-for="(item, index) in projectUsers" :key="item.userId" :value="item.userId">
                            {{ item.userName }}
                        </a-select-option>
                    </a-select>
                </div>
            </div>
        </a-spin>
        <template slot="footer">
            <div class="footer-btns">
                <a-button class="cancel-btn" @click="handleCancel"> 取消 </a-button>
                <a-button :class="[``, !isModify ? 'disabled-btn' : 'confirm-btn']" type="primary" :disabled="!isModify || loading"
                    @click="handleConfirm"> 确认 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { message } from 'ant-design-vue';
import { watch, ref, computed } from 'vue-demi';
import { getProjectUser, authorizationUser } from '../api/experiment';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        ExperimentId: {
            type: String | Number | null,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false)
        }
        // 项目组成员
        const projectUsers = ref([]);
        // 是否正在加载
        const loading = ref(false);
        // 只读用户
        const readUsers = ref([]);
        // 读写用户
        const readAndWriters = ref([]);
        // 初始化只读用户
        const initReadUsers = ref([]);
        // 初始化编辑用户
        const initReadAndWriters = ref([]);
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                loading.value = true;
                const res = await getProjectUser({
                    ExperimentId: props.ExperimentId
                });
                if(res.code === 200) {
                    projectUsers.value = res.data.userlist;
                    initReadUsers.value = res.data.readlist.map(Element => {
                        return Element.userId;
                    });
                    initReadAndWriters.value = res.data.writelist.map(Element => {
                        return Element.userId;
                    });
                    readUsers.value = [...initReadUsers.value];
                    readAndWriters.value = [...initReadAndWriters.value];
                    loading.value = false;
                }
            }
        }, { immediate: true });

        const isModify = computed(() => {
            const isReadSame = JSON.stringify(initReadUsers.value.sort()) === JSON.stringify(readUsers.value.sort());
            const isWriteSame = JSON.stringify(initReadAndWriters.value.sort()) === JSON.stringify(readAndWriters.value.sort());
            return !(isReadSame && isWriteSame);
        })
        // 确认
        const handleConfirm = async () => {
            const res = await authorizationUser({
                experimentId: props.ExperimentId.toString(),
                readUsers: readUsers.value,
                readAndWriters: readAndWriters.value
            })
            if (res.code === 200 || res.code === 204) {
                message.success(res.message);
                context.emit('update:visible');
                context.emit('onAhtorization')
            }
        }
        const handleReadChange = (value) => {
            value.forEach(element => {
                const index = readAndWriters.value.indexOf(element);
                if(index !== -1) {
                    readAndWriters.value.splice(index,1);
                }
            })
        }
        const handelWriteChange = (value) => {
            value.forEach(element => {
                const index = readUsers.value.indexOf(element);
                if(index !== -1) {
                    readUsers.value.splice(index,1);
                }
            })
        }
        return {
            handleCancel,
            projectUsers,
            loading,
            readUsers,
            readAndWriters,
            isModify,
            handleConfirm,
            handleReadChange,
            handelWriteChange
        }
    }
}
</script>

<style lang="less" scoped>
.auth-ctn {
    width: 90%;
    margin: 0 auto;

    .editable {
        margin-top: 24px;
    }
}

.footer-btns {
    text-align: center;


    .cancel-btn {
        background: #eeeeee;
    }
}
</style>