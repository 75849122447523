<!-- 生成实验报告弹窗 -->
<template>
    <a-modal :width="600" :visible="visible" @cancel="handleCancel" title="生成实验报告">
        <a-spin :spinning="spinning" :tip="tip">
            <a-form-model style="width: 80%;margin: 0 auto;" ref="formRef" :model="formData" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 5 },
            }" :wrapper-col="{ span: 19 }">
                <a-form-model-item label="文件名" prop="fileName" :rules="[{ required: true, message: '请输入文件名' }, { validator: fileNameValidator }]">
                    <a-input v-model="formData.fileName" placeholder="请输入"></a-input>
                </a-form-model-item>
                <a-form-model-item label="文件类型" prop="fileType" required>
                    <a-select v-model="formData.fileType">
                        <a-select-option :value="`.pdf`"> pdf文件（.pdf） </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="报告内容" prop="content"
                    :rules="[{ required: true, validator: contentValidator }]">
                    <a-checkbox v-if="visible" :defaultChecked="true" @change="(e) => onCheckedChange(e, 'report')">
                        实验报告
                    </a-checkbox>
                </a-form-model-item>
                <!-- <a-form-model-item label="水印">
                    <a-checkbox :checked="hasWaterMark" @change="handleWaterMarkCheck">
                    </a-checkbox>
                </a-form-model-item>
                <a-form-model-item v-if="hasWaterMark" label="水印内容" prop="waterMarkContent"
                    :rules="[{ required: true, whitespace: true, message: '请输入' }, { max: 10, message: '最多不超过十个字符' }]">
                    <a-input v-model="formData.waterMarkContent" placeholder="请输入"></a-input>
                </a-form-model-item> -->
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" :loading="spinning" @click="handleCancel">
                    取消
                </a-button>
                <a-button :disabled="loading" :loading="spinning" type="primary" class="confirm-btn"
                    @click="hanleConfirm">
                    导出
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { getExperimentReport, getReportInitData, makeExperimentReport } from '../../api/experiment';
import PDFPanel from '../PDFPanel.vue';
import { message, Modal } from 'ant-design-vue';
import router from '../../router';
import JSZip from 'jszip';
import dayjs from 'dayjs';
import { isExcelIOLoad, loadExcelIO } from '../../plugins/KFormDesign/spreadjs';
//import '../../../public/scripts/'
export default {
    props: ["visible", "Id", "eData"],
    setup(props, context) {
        // 对话框取消
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        // 表格ref
        const formRef = ref(null);
        // 表格数据
        const formData = ref({
            fileName: "实验报告",
            fileType: ".pdf",
            content: ["report"],
            waterMarkContent: '',
        });

        const hanleOriginPrint = () => {
            const href = router.resolve({
                name: 'pdfPage',
                query: {
                    id: props.Id ? props.Id : experimentDeatail.value.id
                }
            });
            window.open(href.href, '_blank');
        }
        const blobToObj = (data) => {
            return new Promise((resolve, reject) => {
                let reader = new FileReader();
                reader.readAsText(data, 'utf-8');
                reader.onload = function () {
                    try {
                        resolve(JSON.parse(reader.result))
                    } catch (error) {
                        resolve({
                            code: 200,
                            message: '获取文件信息成功'
                        })
                    }
                }
            })
        }


        // 确定按钮点击
        const hanleConfirm = () => {
            formRef.value.validate(async (valid) => {
                if (valid) {
                    tip.value = '正在生成中'
                    loading.value = true
                    const excelList = experimentDeatail.value.content.list.filter(item => item.type === 'excel-pro');
                    const fileList = excelList.map((item, index) => {
                        return {
                            key: item.key,
                            fileName: `附件${index + 1}-${item.label}.xlsx`
                        }
                    })
                    const res = await getExperimentReport({
                        experimentId: props.Id,
                        hasWaterMark: hasWaterMark.value,
                        waterMarkContent: hasWaterMark.value ? formData.value.waterMarkContent : ''
                    })
                    console.log(res);
                    if (res.type === 'application/json') {
                        const error = await blobToObj(res);
                        message.error(error.message)
                        loading.value = false;
                        return;
                    }
                    if (res.status === 200) {
                        const pdfBlob = new Blob([res.data], {
                            type: 'application/octet-stream'
                        });
                        if (excelList.length > 0) {
                            tip.value = '正在生成附件...';
                            const zip = new JSZip();
                            const folder = zip.folder(`实验报告`);
                            folder.file(`实验报告-${experimentDeatail.value.title}-${experimentDeatail.value.experimentNo}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.pdf`, pdfBlob);
                            try {
                                for (let i = 0; i < excelList.length; ++i) {
                                    const excelRes = await getSingleExcelFile(excelList[i].options.customData, fileList[i].fileName);
                                    if (excelRes.succcess) {
                                        folder.file(fileList[i].fileName, excelRes.blob, {

                                        });
                                    }
                                }
                            } catch (error) {
                                message.error('附件生成失败')
                            }

                            zip.generateAsync({ type: "blob" }).then(function (content) {
                                // see FileSaver.js
                                saveAs(content, `${formData.value.fileName}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.zip`);
                                message.success('导出成功');
                                context.emit('onMakeReportSuccess');
                                context.emit('update:visible', false);
                            });
                        }
                        else {
                            saveAs(pdfBlob, `${formData.value.fileName}-${dayjs().format('YYYY-MM-DD-HH-mm-ss')}.pdf`);
                            message.success('导出成功');
                            context.emit('onMakeReportSuccess');
                            context.emit('update:visible', false);
                        }
                    }
                    else {
                        message.error(res.data.message);
                    }
                    loading.value = false;
                }
            })
            //const re = /[ \/ | \\ | \: | \| | \* | \" | \< | \> | \?]*/g
        };


        // 获取单个excel文件的文件流
        const getSingleExcelFile = async (json, fileName) => {
            //const GCExcel = await import('../../../public/scripts/gc.spread.excelio.15.1.1.min.js');
            return new Promise((resolve, reject) => {
                const excelio = new GC.Spread.Excel.IO();
                //const excelio = new GCExcel.value.IO();
                excelio.save(json, function (blob) {
                    resolve({
                        succcess: true,
                        blob: blob
                    })
                }, function (error) {
                    resolve({
                        succcess: false
                    })
                })
            })

        }
        // 报告内容勾选
        const onCheckedChange = (e, type) => {
            if (e.target.checked) {
                formData.value.content.push(type);
            }
            else {
                const index = formData.value.content.indexOf(type);
                index !== -1 && formData.value.content.splice(index, 1);
            }
        };
        // 内容校验器
        const contentValidator = async (rule, value, callback) => {
            if (value.length < 1) {
                return callback(new Error("请选择报告内容"));
            }
            else {
                callback();
                return callback();
            }
        };
        // 是否正在加载
        const loading = ref(false);
        //
        const spinning = computed(() => {
            return loading.value;
        })
        // 实验详情数据
        const experimentDeatail = ref({
            title: "实验报告",
            content: ""
        });
        // 加载提示
        const tip = ref("正在加载中");
        // 监听可见
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                if (!isExcelIOLoad.value) {
                    await loadExcelIO();
                    //console.log(GC)
                }
                if (props.Id) {
                    formRef.value && formRef.value.resetFields();
                    formData.value.content = ["report"];
                    tip.value = "正在加载中...";
                    loading.value = true;
                    const res = await getReportInitData({ Id: props.Id });
                    if (res.code === 200) {
                        experimentDeatail.value = res.data;
                        experimentDeatail.value.content = JSON.parse(experimentDeatail.value.content)
                        formData.value.fileName = `实验报告-${experimentDeatail.value.title}`;
                    }
                    //await loadScript()
                    loading.value = false;

                }
                else if (props.eData) {
                    formRef.value && formRef.value.resetFields();
                    formData.value.content = ["report"];
                    tip.value = "正在加载中...";
                    loading.value = true;
                    experimentDeatail.value = props.eData;
                    formData.value.fileName = `实验报告-${experimentDeatail.value.title}`;
                    //await loadScript()
                    loading.value = false;
                }

            }
        }, { immediate: true, deep: true });

        const PDFRef = ref(null);

        const GCExcel = ref(null);

        const loadingMessage = ref('正在加载中');
        // 是否存在水印
        const hasWaterMark = ref(false);
        // 水印更改
        const handleWaterMarkCheck = (e) => {
            hasWaterMark.value = e.target.checked;
        }
        // 文件名合法检测
        const fileNameValidator = async (_rule, value, callback) => {
            const re = /[ \/ | \\ | \: | \| | * | \" | \< | \> | \?]+/g;
            //console.log(re.test(value.toString()))
            if(re.test(value.toString())) {
                callback(new Error('文件名不能包含以下字符： / \\ : * " < > | ?'))
            }
            else {
                callback();
            }
        }
        return {
            handleCancel,
            formRef,
            formData,
            onCheckedChange,
            hanleConfirm,
            contentValidator,
            experimentDeatail,
            loading,
            tip,
            PDFRef,
            hanleOriginPrint,
            spinning,
            loadingMessage,
            hasWaterMark,
            handleWaterMarkCheck,
            fileNameValidator
        };
    },
    components: { PDFPanel }
}
</script>