<template>
    <a-drawer
      title="流程状态查看"
      :width="304"
      @close="onClose"
      :visible.sync="visible"
      >
        <a-spin :spinning="loading" tip="正在加载..." :delay="300">
            <div class="statu-flow-head">
                <div class="item-label">
                    实验编号：
                </div>
                <div class="item-value">
                    {{ flowData.experimentNo }}
                </div>
                <div class="item-label" style="margin-top: 24px;">
                    实验名称：
                </div>
                <div class="item-value">
                    {{ flowData.title }}
                </div>
            </div>
            <div class="statu-flow-content">
                <a-timeline>
                    <a-timeline-item v-for="(item,index) in flowData.experimentStatusFlowViews" :key="index">
                        <template slot="dot">
                            <div v-if="index === flowData.experimentStatusFlowViews.length -1" class="index-icon">
                            {{ index + 1 }}
                            </div>
                            <div v-else>
                                <svg-icon name="statu-check" class="check-icon"></svg-icon>
                            </div>
                        </template>
                        <div class="statu-ctn">
                            <div class="statu-title">
                                {{ item.operationTypeName }}                            
                            </div>
                            <div class="statu-content">
                                <div class="statu-item">
                                    <div class="statu-item-label">
                                        {{ getTimeLabel(item) }}：
                                    </div>
                                    <div class="statu-item-value">
                                        {{ item.createdTime }}
                                    </div>
                                </div>
                                <div class="statu-item">
                                    <div class="statu-item-label">
                                        {{ getUserLabel(item) }}：
                                    </div>
                                    <div class="statu-item-value">
                                        {{ item.userName }}
                                    </div>
                                </div>
                                <!-- <p> 诚信分 </p> -->
                                <div v-if="item.getGoodFaithText" class="statu-item">
                                    <div class="statu-item-label">
                                        诚信分 ：
                                    </div>
                                    <div class="statu-item-value">
                                        -{{ item.grade }}分
                                    </div>
                                </div>
                                <div v-if="item.description" class="statu-item">
                                    <div class="statu-item-label">
                                        备注:
                                    </div>
                                    <div class="statu-item-value">
                                        {{ item.description }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a-timeline-item>
                </a-timeline>
            </div>
        </a-spin>
    </a-drawer>
</template>

<script>
import { ref, watch } from 'vue-demi';
import { getExperimentStatusFlow } from '../../../api/experiment';
import { getGoodfaithclauseList } from '../../../api/goodfaithclause';

export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        experimentId: {
            type: String | null,
            required: true
        }
    },
    setup(props, context) {
        const loading = ref(false);
        const onClose = () => {
            context.emit('update:visible', false);
        }
        const flowData = ref({});
        const goodFaithList = ref([]);
        watch(() => props.visible, async (newVal) => {
            if(newVal) {
                loading.value = true;
                const res = await getExperimentStatusFlow({
                    experimentId: props.experimentId
                });
                if(res.code === 200) {
                    flowData.value = res.data;
                    loading.value = false;
                }
                /* const gRes = await getGoodfaithclauseList();
                if(gRes.code == 200) {
                    goodFaithList.value = gRes.data;
                }  */
            }
        })
        // 获取操作时间label
        const getTimeLabel = (item) => {
            if(item.operationType == 1) {
                return '创建时间'
            }
            else if(item.operationType == 2) {
                return '编辑时间'
            }
            else if(item.operationType == 3) {
                return '申请时间'
            }
            else if(item.operationType == 4) {
                return '申请时间'
            }
            else {
                return '申请时间'
            }
        }
        // 获取操作人label
        const getUserLabel = (item) => {
            if(item.operationType == 1) {
                return '创建人'
            }
            else if(item.operationType == 2) {
                return '最近修改人'
            }
            else if(item.operationType == 3) {
                return '申请人'
            }
            else if(item.operationType == 4) {
                return '审核人'
            }
            else {
                return '申请人'
            }
        }
        // 获取诚信条款数据
        const getGoodFaithText = (goodFaithClauseId) => {
            const temp = goodFaithList.value.filter(element => {
                return element.id == goodFaithClauseId;
            });
            if(temp.length === 0) {
                return ''
            }
            else{
                return temp[0].name;
            }
        }
        return {
            onClose,
            loading,
            flowData,
            getTimeLabel,
            getUserLabel,
            goodFaithList,
            getGoodFaithText
        }
    }
}
</script>

<style lang="less" scoped>
.statu-flow-head {
    padding: 16px 8px;
    background: #F0F5FF;
}
.statu-flow-content {
    margin-top: 25px;
    .check-icon {
        color:  @srims-primary-color;
        fill:  @srims-primary-color;
        font-size: 22px;
        width: 22px;
        height: 22px;
    }
    .statu-ctn {
        margin-left: 9px;
        font-size: 12px;
        .statu-content {
            margin-top: 8px;
            .statu-item {
                display: flex;
                .statu-item-label {
                    white-space: nowrap;
                    width: 75px;
                    text-align: right;
                    color: rgba(0, 0, 0, 0.85);
                }
                .statu-item-value {
                    color: rgba(0, 0, 0, 0.65);
                }
            }
            .statu-item + .statu-item {
                margin-top: 4px;
            }
        }
    }
    .statu-title {
        font-size: 14px;
    }
}
.index-icon {
    background:  @srims-primary-color;
    color: #FFFFFF;
    font-size: 14px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>