import { render, staticRenderFns } from "./projectDetailModal.vue?vue&type=template&id=51f6f926&scoped=true&"
import script from "./projectDetailModal.vue?vue&type=script&lang=js&"
export * from "./projectDetailModal.vue?vue&type=script&lang=js&"
import style0 from "./projectDetailModal.vue?vue&type=style&index=0&id=51f6f926&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51f6f926",
  null
  
)

export default component.exports